import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';
import { convertClassRostNumToMembershipId } from '@/myphoenix/utils/course-functions';
import { CreateStudentLoaRequest, LoaReasonsResponse } from '@/types/api/student';

/** CancelStudentLoaRequest */
export interface CancelStudentLoaRequest {
  resourceID?: string;
  userID?: string;
}

export type CreateLoaRequestType = {
  createStudentLoaRequest:CreateStudentLoaRequest,
};
export type CancelLoaRequestType = {
  cancelStudentLoaRequest:CancelStudentLoaRequest,
};

export const studentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStudentLoa: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getLoaByPersonId(personId);
          const loa = results?.data;
          if (loa?.startDate && loa?.endDate) {
            return {
              data: {
                requestDate: loa?.requestDate,
                startDate: loa?.startDate,
                endDate: loa?.endDate,
                statusCode: loa?.statusCode,
                statusCodeDescription: loa?.status,
              },
            };
          }
          return { data: null };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Loa'],
    }),
    getStatusesByPersonId: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getStatusesByPersonId(personId);
          return { data: results?.data?.statuses };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getCourseOutcomes: build.query({
      async queryFn(
        { programId, token }:
        { programId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getCourseOutcomes(programId);
          let data: any = null;
          if (Array.isArray(results?.data) && results.data.length) {
            data = {};
            results.data.forEach((outcome) => {
              const membershipId = convertClassRostNumToMembershipId(outcome.classRostNum);
              data[membershipId] = {
                membershipId,
                courseTitle: outcome.courseTitle,
                templateCode: outcome.courseId,
                sectionId:
                  convertClassRostNumToMembershipId(outcome.courseOfferingId),
                deadDate: outcome.deadDate,
                groupId: outcome.groupId,
                offeringStartDate: outcome.offeringStartDate,
                offeringEndDate: outcome.offeringEndDate,
                qualificationPoints: outcome.qualificationPoints,
              };
              return null;
            });
          }
          return { data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getCBEDataByPersonId: build.query({
      async queryFn(
        { personId, programCode, programVersion, token }:
        { personId: string, programCode: string, programVersion: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getCBEDropDate(personId, programCode, programVersion);
          const cbe = results?.data?.results;
          const data = {
            data: {
              lastARADate: cbe?.[0].value,
              estimatedDropDate: cbe?.[1].value,
            },
          };
          return data;
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getStudentTransferCreditsData: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getStudentTransferCreditsDataByPersonId(personId);
          return results;
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    postCreateLoa: build.mutation({
      async queryFn(
        { createLoaRequest, token }:
        { createLoaRequest: CreateLoaRequestType, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .postStudentLoa(createLoaRequest);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      invalidatesTags: (result, error) => {
        // Only invalidate tags if there's no error and we have a result
        if (error) {
          return [];
        }
        return ['Loa'];
      },
    }),
    postCancelLoa: build.mutation({
      async queryFn(
        { cancelLoaRequest, token }:
        { cancelLoaRequest: CancelLoaRequestType, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .postCancelStudentLoa(cancelLoaRequest);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      invalidatesTags: ['Loa'],
    }),

    getLoaReasons: build.query<LoaReasonsResponse, unknown>({
      async queryFn(
        { token }:
        { token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getLoaReasons();
          return results;
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),

    getStudentLoaContainmentPeriod: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getStudentLOAContainmentPeriod(personId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Loa'],
    }),
    getStudentLoaHistory: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getStudentLOAHistory(personId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Loa'],
    }),
  }),
});

export const {
  useGetStudentLoaQuery,
  useGetStatusesByPersonIdQuery,
  useGetCourseOutcomesQuery,
  useGetCBEDataByPersonIdQuery,
  useGetStudentTransferCreditsDataQuery,
  usePostCreateLoaMutation,
  usePostCancelLoaMutation,
  useGetStudentLoaContainmentPeriodQuery,
  useGetStudentLoaHistoryQuery,
  useGetLoaReasonsQuery,
} = studentApi;
